<script setup lang="ts">
import type { CtaBlockStoryblok } from '@/storyblok-types';
import type { ButtonVariants } from 'shared-components';

withDefaults(defineProps<{
  blok: CtaBlockStoryblok;
  withoutContainer?: boolean;
}>(), { withoutContainer: false, })

const { getStoryblokUrl } = useStoryblokData();

const alignment = computed(() => `text-${__props.blok.alignment}`);

const buttonVariant = computed(() => {
  const settings: Record<string, ButtonVariants['variant']> = {
    'border-dark': 'secondary',
    'border-green': 'ctaInverted',
    dark: 'primary',
    green: 'cta',
    pink: 'pink',
    purple: 'info',
    yellow: 'yellow',
  };

  return __props.blok.color
    ? settings[__props.blok.color]
    : settings.green;
});

const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));
</script>

<template>
  <div
    v-editable="blok"
    :class="[alignment, spacingClasses]"
    :style="{ backgroundColor: blok.backgroundColor.value }"
  >
    <div :class="{'container max-w-5xl': !withoutContainer}">
      <Button
        v-if="blok.text"
        as-child
        class="max-w-[310px] md:max-w-[440px]"
        type="submit"
        :size="blok.size || 'medium'"
        :variant="buttonVariant"
      >
        <NuxtLink :to="getStoryblokUrl(blok.link)">
          {{ blok.text }}
        </NuxtLink>
      </Button>
    </div>
  </div>
</template>
